import React from 'react';
import { Link } from 'react-router-dom';
import Container from '../basic/container.component';

function Footer() {
  return (
    <div className="footer">
      <div className="bg-green p-0.5 w-28"></div>
      <Container>
        <div className="flex justify-between my-0.5">
          <a
            href="https://campusrunz.com/"
            target="_blank"
            className="hover:underline text-white font-semibold"
            rel="noreferrer"
          >
            Create an Account
          </a>
          <a
            href="https://campusrunz.com/"
            target="_blank"
            rel="noreferrer"
            className="hover:underline text-white"
          >
            Need help?
          </a>
        </div>
      </Container>
    </div>
  );
}

export default Footer;
