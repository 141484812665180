import React, { lazy, Suspense, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RouteGuard from './core/guards/route.guards';
import { AUTH_TYPE } from './core/const/const';
import Loader from './core/components/basic/loader.component';
import { ReactNotifications } from 'react-notifications-component';
import { GlobalDebug } from './core/services/helpers.service';
import Page from './core/components/wrapper/page.component';

const Landing = lazy(() => import('./pages/public/landing.page'));
const NotFound = lazy(() => import('./pages/public/notfound.public.page'));
const Event = lazy(() => import('./pages/public/event.page'));
const Payment = lazy(() => import('./pages/public/payment.page'));

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  const [isAuth] = useState(false);

  useEffect(() => {
    (process.env.NODE_ENV === 'production' ||
      process.env.REACT_APP_ENV === 'STAGING') &&
      GlobalDebug(false);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <ReactNotifications />
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route
              element={<RouteGuard isAuth={isAuth} type={AUTH_TYPE.PUBLIC} />}
            >
              <Route
                path="/"
                element={
                  <Page logout={() => {}}>
                    <Landing />
                  </Page>
                }
              />
            </Route>

            <Route
              element={<RouteGuard isAuth={isAuth} type={AUTH_TYPE.PUBLIC} />}
            >
              <Route
                path="/tickets/:event"
                element={
                  <Page logout={() => {}}>
                    <Event />
                  </Page>
                }
              />
            </Route>

            <Route
              element={<RouteGuard isAuth={isAuth} type={AUTH_TYPE.PUBLIC} />}
            >
              <Route
                path="/payment"
                element={
                  <Page logout={() => {}}>
                    <Payment />
                  </Page>
                }
              />
            </Route>

            <Route
              element={<RouteGuard isAuth={isAuth} type={AUTH_TYPE.PUBLIC} />}
            >
              <Route
                path="*"
                element={
                  <Page logout={() => {}}>
                    <NotFound />
                  </Page>
                }
              />
            </Route>
          </Routes>
        </Suspense>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
