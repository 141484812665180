import React from 'react';

function Container({ children = <div></div> }: { children: any }) {
  return (
    <div className="flex-1 overflow-y-auto p-3 w-11/12 mx-auto md:container md:mx-auto">
      {children}
    </div>
  );
}

export default Container;
