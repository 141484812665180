import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '../basic/container.component';
import { RiTicket2Fill } from 'react-icons/ri';
import Logo from '../../../assets/images/logo-whilte.png';
import { AiOutlineLeft } from 'react-icons/ai';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Header({ logout }: { logout: any }) {
  const navigate = useNavigate();

  return (
    <div className="bg-gradient shadow-xl">
      <Container>
        <div className="flex justify-between">
          <AiOutlineLeft
            className="cursor-pointer text-2xl text-white font-light"
            onClick={() => navigate(-1)}
          />

          <div
            className="flex justify-between items-center"
            onClick={() => navigate('/')}
          >
            <div className="relative">
              <RiTicket2Fill className="cursor-pointer text-2xl text-white font-light mr-0.5" />
            </div>
            <p className="text-white">
              <span className="font-black">Quick</span>Shop
            </p>
          </div>

          <div className="w-8 h-8">
            <img src={Logo} alt="image" />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Header;
