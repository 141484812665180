import React from 'react';
import Container from '../basic/container.component';
import Header from '../shared/header.component';
import Footer from '../shared/footer.component';

function Page({ children, logout }: { children: any; logout: any }) {
  return (
    <div className="flex flex-col h-screen">
      <Header logout={logout} />
      <Container>{children}</Container>
      <Footer />
    </div>
  );
}

export default Page;
