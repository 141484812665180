import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AUTH_TYPE, REDIRECT_URL } from '../const/const';
import { Guard } from '../interfaces/guards.interface';

function RouteGuard({ children, isAuth, type }: Guard) {
  if (!isAuth && type === AUTH_TYPE.ADMIN) {
    return <Navigate to={REDIRECT_URL.Public} replace />;
  }

  if (isAuth && type === AUTH_TYPE.PUBLIC) {
    return <Navigate to={REDIRECT_URL.Admin} replace />;
  }

  return children || <Outlet />;
}

RouteGuard.propTypes = {
  children: PropTypes.node,
  isAuth: PropTypes.bool,
  type: PropTypes.string
};

RouteGuard.defaultProps = {
  isAuth: false,
  type: AUTH_TYPE.PUBLIC
};

export default RouteGuard;
