import React from 'react';
import Modal from 'react-responsive-modal';
import Icon from '../../../assets/images/preloader.svg';

function Loader({ message = 'Loading' }: { message?: string }) {
  return (
    <Modal
      classNames={{ modal: 'rounded-md no-padding' }}
      open
      showCloseIcon={false}
      onClose={() => {}}
      center
    >
      <div className="absolute top-0 w-full h-screen bg-gray-700 flex flex-col items-center justify-center bg-opacity-95">
        <div>
          <img src={Icon} className="w-40 h-auto" alt="circle" />
        </div>
        <p className="text-base text-black-l">{message}</p>
      </div>
    </Modal>
  );
}

export default Loader;
